<template>
  <div>
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="cart page">
      <div class="">
        <div class="banner cart-banner ">
          <div class="container">
            <h3 class="title">Cart</h3>
          </div>
        </div>
        <div class="content container">
          <div class="row my-5">
            <div class="col-md-8">
              <table class="table table-responsive"  >
                <thead >
                
               
                  <tr style="background: darkblue">

                    <th scope="col" style ="color:white">Groceries</th>
                    <th scope="col" v-if="delivery_mode==='Delivery'"><i style ="color:white;margin-left:-21px; font-size:11px">(may arrive in 2 Hours)</i></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                     <th scope="col"></th>
                   
                  </tr>
                </thead>
                <thead>
                  <tr>

                    <th scope="col">Product</th>
                    <th scope="col"></th>
                    <th scope="col">Qty</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Total</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, i) in cart"
                    v-bind:key="i"
                  >

                    <td class="tdb">

                      <img
                        v-if="row.product.img_url.includes('https://cdn.marketsquareng.website')"
                        :src="row.product.img_url"
                        alt=""
                        class="img"
                      >
                      <img
                        v-else
                        :src="'https://admin.sundrymarkets.com'+row.product.img_url"
                        alt=""
                        class="img"
                      >

                    </td>
                    <td class="productname tdb">{{row.product.name}}</td>
                    <td class="tdb">
                      <input
                        v-if="row.product.name.includes('/KG') || row.product.name.includes('/ KG')"
                        oninput="validity.valid||(value='');"
                        :id="i"
                        type="number"
                        min="0.001"
                        step="any"
                        class="number qty"
                        v-model=row.quantity
                        @keypress="restrictChars($event)"
                        @change="inputChange(i, row.product.id)"
                      >

                      <input
                        v-else
                        :id="i"
                        type="number"
                        min="1"
                        step="1"
                        class="number qty"
                        v-model=row.quantity
                        oninput="validity.valid||(value='');"
                        @keypress="restrictChars($event)"
                        @change="inputChange(i, row.product.id)"
                      />
                    </td>
                    <td class="tdb">₦{{formatPrice(row.unit_price)}} </td>
                    <td class="tdb">₦{{formatPrice(row.price)}} </td>
                    <td class="tdb"> <img
                        @click="removeItem(row)"
                        style="cursor:pointer;"
                        src="../assets/img/cancel.png"
                        alt=""
                        title="Remove item"
                      ></td>
                  </tr>

                </tbody>
              </table>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-bold">Cart Total</h5>
                  <table class="table table-sm">

                    <tbody>
                      <tr>
                        <td class="float-left">Cart Subtotal</td>
                        <td class="float-right">₦{{formatPrice(cart_subtotal)}}</td>
                      </tr>

                    </tbody>
                  </table>
                  <div class="mt-4 total d-flex  justify-content-between">
                    <p>Total</p>
                    <h5>₦{{formatPrice(cart_total)}}</h5>
                  </div>
                  <button
                    id="continue"
                    @click="$router.push('home')"
                  >Continue Shopping</button>
                <div>
                  <div >
                    <button
                      id="continue" v-if="showPromotion"
                      @click="$router.push('category/backtoschool')"
                    >explore additional back to School promotion
                    </button>
                  </div>
                </div>
                  <button
                    v-bind:disabled="cart_total < 10000"
                    v-bind:class="cart_total < 10000 ? 'disabled': ''"
                    @click="handleCheckout"
                  >Proceed to checkout</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import TopNav from '@/components/TopNav.vue'
import Footer from '@/components/Footer.vue'
import Tops from '@/components/Tops.vue'
// import { trackEvent } from '@/services/analytics';
export default {
  name: 'Cart',
  components: {
    TopNav, Footer,Tops
  },
  data () {
    return {
      cart: [],
      cart_total: 0,
      showPromotion:false
    }
  },
  beforeMount () {
    this.$store.dispatch('ToggleShowSearch', true)
  },
  created(){
    this.store = this.$store.getters.store;
    this.delivery_mode= this.store.mode
  },  
  mounted () {
     this.getProductInCart();
     this.cartPromotion();

    // this.cart = this.$store.getters.cart;

    // this.cart.forEach(i => {
    //   this.cart_subtotal += Number(i.price);
    // })
    // this.cart_total = this.cart_subtotal
   
  },
  computed: {
    cart_subtotal () {
      this.cart = this.$store.getters.cart;
      let total = 0;
      this.cart.forEach(i => {
        total += Number(i.price);
      })
      this.cart_total = total;
      return total;
    }
  },
  
  watch: {
    // Watch for changes in the cart data and trigger event tracking
    cart: {
      handler(newCart) {
        this.trackAddToCartEvent(newCart); // Call event tracking when cart is updated
      },
      deep: true, // Ensure nested properties are watched
    },
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = 'Your Cart';
        }
    },
  },

  methods: {
     // Method to track the 'add_to_cart' event dynamically
    trackAddToCartEvent(newCart) {
      // Ensure we only send event if there are items in the cart
      if (newCart.items && newCart.items.length > 0) {
        if (typeof gtag !== 'undefined') {
          gtag('event', 'add_to_cart', {
            currency: 'NGN',
            value: newCart.total, // The total value of the cart
            items: newCart.items.map(item => ({
              item_id: item.id,          // Item ID
              item_name: item.name,      // Item name
              item_category: item.category, // Item category
              quantity: item.quantity,   // Quantity of the item
              price: item.price,         // Item price
            })),
          });

          console.log('add_to_cart event sent dynamically');
        } else {
                console.warn('gtag is not defined. Cannot send add_to_cart event dynamically.');
            }
        
        
      }
    },
     getProductInCart(){

      let cart = this.$store.getters.cart;

      let productsInCart = cart.reduce((acc,prev) => { acc.push(prev.product.id); return acc }, [])

      let req = {
        what: "getProductInCart",
        showLoader: false,
        params : {
          'cart' : JSON.stringify(productsInCart)
        }
      };

      this.$request.makeGetRequest(req)
        .then(res => {

          // if (res.type == 'getproduct') {
            let pro = res.data.data;
            if (pro.length > 0){
              cart = cart.map((item) => {
                item.product.price = pro.find((product) => item.product.id == product.id).sellingprice
                item.unit_price = item.product.price
                item.price = item.quantity * item.unit_price
                return item
              })
            }
            this.$store.dispatch('addToCart', cart)
            this.loader.hide()
          // }

        })
        .catch(error => {
          
        });
    },
    cartPromotion(){
      let req = {
        what: "cartPromotion",
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {
            log(response);
            if (response.status==true) {
              this.showPromotion= true
            }else{
              this.showPromotion=false;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },

    removeItem (row) {
      let index;
      let cart = this.$store.getters.cart;
      cart.forEach((i, ind) => {
        if (i.product.id == row.product.id) {
          index = ind
        }
      })
      cart.splice(index, 1);
      this.$store.dispatch('addToCart', cart)
    },
    inputChange (id, product_id) {

      var value = document.getElementById(id).value;
      if (value == '') {
        document.getElementById(id).value = 1;
        value = 1;
      }
      this.updateCartQuantity(value, product_id, "input");
    },
    updateCartQuantity (value, product_id, action) {
      let cart_array = this.$store.getters.cart;
      cart_array.forEach(i => {
        if (product_id == i.product.id) {
          if (action == '+') {
            i.quantity = parseInt(i.quantity) + 1;
            i.price += parseInt(i.unit_price);
          }
          else if (action == '-') {
            i.quantity = parseInt(i.quantity) - 1;
            i.price -= parseInt(i.unit_price);
          }
          else {
            i.quantity = value;
            i.price = parseInt(i.unit_price) * value;

          }
        }
      })
      this.$store.dispatch('addToCart', cart_array)
    },
   handleCheckout() {
    if (this.$store.getters.isLoggedIn) {
      let cart = this.$store.getters.cart;
      let cartItems = [];
      // Mapping cart items to the required structure
      cart.forEach(item => {
        cartItems.push({
          product_id: item.product.id,
          quantity: item.quantity,
          unit_price: item.unit_price,
          price: item.price,
        });
      });

      // Prepare the data for the API request
      const data = {
        user_id: this.$store.getters.user.id,
        totalquantity: cart.length,
        total_price: this.cart_total,
        checkout: "0",
        storeid: this.$store.getters.store.id,
        checkoutitems: cartItems,
      };
      console.log('Cart data:', data);

      const req = {
        what: "createcart",
        data: data,
        showLoader: true,
      };

      // Send request to the API to create the cart
      this.$request
        .makePostRequest(req)
        .then(response => {
          if (response && response.status === 'success') {
            // Navigate to the checkout page
            this.$router.push({ name: 'Checkout' });
          } else {
            // Handle non-successful response
            this.$swal.fire('Error', 'There was an issue with creating the cart.', 'error');
          }
        })
        .catch(error => {
          console.error('Error creating cart:', error);
          this.$swal.fire('Error', error.message || 'Something went wrong. Please try again.', 'error');
        });
    } else {
      // If the user is not logged in, redirect to the checkout page
      this.$router.push({ name: 'Checkout' });
    }

    // Track the begin_checkout event dynamically
    // Check if `gtag` is defined before sending the event
        if (typeof gtag !== 'undefined') {
          gtag('event', 'begin_checkout', {
            currency: 'NGN',
            value: this.cart_total,
            items: this.cartItems,
          });

            console.log("begin_checkout event sent for transaction");
      } else {
          console.warn('gtag is not defined. Cannot send begin_checkout.');
      }
  },

    restrictChars: function ($event) {
      if ($event.key !== '-') {
        return true
      }
      else {
        $event.preventDefault();
      }
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
  }
}

</script>

<style scoped>
#continue {
  background: #e8e8e8;
  color: #000066;
}
.cart .card {
  position: sticky;
  top: 25%;
}
tr {
  background: white;
}
@media screen and (max-width:500px) {
  #continue {
      top: 37% !important;
  }
  .cart .card button {
    margin-top: 50px !important;
  }
}
.tdb{
border:0px !important;
}
</style>