<template>
  <div class="">

    <Disclaimer />
    <TopNav></TopNav>
    <Tops></Tops>

    <Cart :home_products="products"></Cart>

    <div class="page">
      <div class="">
        
        <div class="banner home-banner content-desktop" style="text-align: center !important; ">
          <div class="container">
            <div class="banner-text">
              <h3 class="title">Let’s take the burden off you. <br> Shop & get it delivered to your doorstep</h3>
              <p class="subtitle">Food, drinks, groceries, and more available for delivery and pickup.</p>
            </div>
          </div> 
         
          <slider
            class="d-none d-md-block"
            height="400px"
            :ease="0.5"
            :interval="8000"
            :speed="1000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                width="100%"
                 height=""
                alt=""
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider> 
          
          <slider
            class="d-block d-md-none"
            :duration="10000"
            height="150px"
            :speed="8000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                alt=""
                width="100%"
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider>
        </div>
            
            
        <div class="banner home-banner" id="content-mobile" style="text-align: center !important; ">
          <div class="container">
           <!-- <div class="banner-text">
              <h3 class="title">Let’s take the burden off you. <br> Shop & get it delivered to your doorstep</h3>
              <p class="subtitle">Food, drinks, groceries, and more available for delivery and pickup.</p>
            </div>-->
          </div>  
         
          <slider id="six"
            class="d-none d-md-block"
            height="400px"
            :ease="0.5"
            :interval="8000"
            :speed="1000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                width="100%"
                height="100%"
                alt=""
                
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider> 
          
          <slider
            class="d-block d-md-none"
            :duration="10000"
            height="150px"
            :speed="8000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                alt=""
                width="100%"
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider>
        </div>


        <!-- Large modal -->
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="color:black">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" ></button>
              <div class="modal-body">
                <div  >
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    style="      height: 6px; margin-top: -5px;color: white"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                 <img src="../assets/img/banners/Newyear/Popup.jpg" width="100%" height="100%" />
              </div>
            </div>
          </div>
        </div>

        <div class="content container" style="margin-top: 79px;">
          <div class="product-group  container bg-white my-5"  id="content-mobile" style= "justify-content: center;">
            <br>
            <p><b>Shop by categories</b></p>

            <div class ="row"  style= "justify-content: center;">
              <div>
                 <div id = "catbox">
                   <a href="/preparedfood" ><img src="../assets/img/prepared.png" /></a>
                 </div>
                 <p class=" text-center cat"> Prepared<br> Food </p>
              </div>

              <div>
                 <div id = "catbox">
                     <a href="/promotions/Specials" > <img src="../assets/img/Mobile/Specials.png" /></a>
                 </div>

                 <p class=" text-center cat"> Specials </p>
              </div>

              <div>
                <div id = "catbox">   <a href="/category/combos" >    <img src="../assets/img/Mobile/gift-basket.png" /></a></div>
                <p class=" text-center cat">Gift Cards </p>
              </div>
              <div>
                <div id = "catbox"><a href="/category/bakery" > <img src="../assets/img/Mobile/bread.png" /> </a></div>
                <p class=" text-center cat">Bakery  </p>
              </div>         
            </div>

            <div class ="row"  style= "justify-content: center;">
              <div>
                <div id = "catbox">    <a href="/category/groceries">    <img src="../assets/img/Mobile/hamper.png" /></a></div>
                <p class=" text-center cat">Groceries  </p>
              </div>

              <div>
                <div id = "catbox">      <a href="/category/fresh produce">  <img src="../assets/img/Mobile/food.png" /></a></div>
                <p class=" text-center cat">Food  </p>          
              </div>

              <div>
                <div id = "catbox">  <a href="/category/spirits">     <img src="../assets/img/Mobile/wine.png" /></a></div>
                <p class=" text-center cat">Drinks </p>
              </div>

              <div>
                <div id = "catbox">  <a href="/category/medicare">     <img src="../assets/img/Mobile/healthcare.png" /></a></div>
                <p class=" text-center cat">Health & <br> Beauty </p>
              </div>

              <div>
                <div id = "catbox"><a href="/category/household"><img src="../assets/img/Mobile/appliances.png" /></a></div>
                <p class=" text-center cat">Appliances</p>
              </div>
              
              <div>
                <div id = "catbox"> <a href="/category/general"> <img src="../assets/img/Mobile/others.png" /></a></div>
                <p class=" text-center cat">Others</p>
              </div>
            </div>
          </div>

          <div class="product-group container bg-white my-5 ">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Top Products</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="promotions/Specials" style="color: #ff0000;">View More ></a></h5>
            </div>

            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2 "
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.top"
                v-bind:key="index"
                class="col-6 col-md-3 col-lg-2 p-1"
              >
                <Product :product="product" v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="index" />
              </slide>
            </carousel>
          </div>

          <div class="product-group container bg-white my-5 ">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="/category/Beverages" style="color: white; " >Beverages</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Beverages" style="color: #ff0000;">View More ></a></h5>
            </div>

            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2 "
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.beverages"
                v-bind:key="index"
                class="col-xs-6 col-sm-3 col-lg-2 p-1"
              >
                <Product :product="product"
                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
              </slide>
            </carousel>

          </div>

          <div  id="content-mobile"  >
           <div class="product-group  my-5" >
            
            <a href="storelocator" >
                  <img src="../assets/img/shopbanner.png"
                 style="width: 100% ;height: 100%; margin-top: -31px;  margin-bottom: -28px;"/>
                 </a> <h3 class="" style="position: absolute;margin-top: -55px;font-size: 12px;color: white;font-weight: bolder;margin-left: 175px;">
                Find Products from the <br />
                Stores you already shop at
              </h3>
            </div>
          </div>

        
          <div class="product-group container bg-white my-5">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="/category/water" style="color: white; " >Water</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/water" style="color: #ff0000;">View More ></a></h5>
            </div>

            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2"
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.water"
                v-bind:key="index"
                class="col-xs-6 col-sm-3 col-lg-2 p-1"
              >
                <Product :product="product"
                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
              </slide>
            </carousel>

          </div>


          <div class="product-group container bg-white my-5">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="/category/Commodities" style="color: white; " >Commodities</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Commodities" style="color: #ff0000;">View More ></a></h5>
            </div>
            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2"
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.commodities"
                v-bind:key="index"
                class="col-xs-6 col-sm-3 col-lg-2 p-1"
              >
                <Product :product="product" 
                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
              </slide>
            </carousel>

          </div>
        </div>
      </div>
      
      <storeSelector></storeSelector>
      <Questionnaire></Questionnaire>

     <!-- Product Modal -->
      <ProductModal :viewproduct="viewproduct" :pro="pro" :products="products" />
    </div>

    <Cart :home_products="products"></Cart>

    <!--<pageDescription />-->
    <Footer></Footer>
  </div>
</template>

<script>
import StoreSelector from '@/components/StoreSelector.vue'
import Cart from '@/components/CartComponent.vue'
import TopNav from '@/components/TopNav.vue'
import Tops from '@/components/Tops.vue'
import Footer from '@/components/Footer.vue'
import Disclaimer from '@/components/Disclaimer.vue'
import Questionnaire from "@/components/Questionnaire";
import Product from '@/components/Product.vue'
import ProductModal from '@/components/ProductModal.vue'
import pageDescription from '@/components/pageDescription.vue'

export default {
  name: 'Home',
  components: {
    StoreSelector,Cart,TopNav, Tops, Footer, Disclaimer, Questionnaire, Product, ProductModal, pageDescription
  },
 
  data () {
    return {
      image_url: this.$request.url,
      showSearch: false,
      products: {},
      pro: '',
      viewproduct: false,
      loader: '',
      categories: [],
      promotions: [],
      banners: [],
    }
  },
  
  beforeMount () {
 
    this.$store.dispatch('ToggleShowSearch', true);
    this.loader = this.$loading.show();
  },
  created () {
    this.banners = this.$store.getters.banners.filter( (image) => image.category == null || image.category == 'null');
    // this.banners = this.banners != [] ? this.banners.reverse() : this.banners;
  },
  mounted () {
 if (!localStorage.getItem("black_friday_modal")) {
      localStorage.setItem("black_friday_modal", true);
      $("#myModal").modal("show");
    }

    this.fetchProducts();
    this.fetchBanners();
    this.fetchCategories();
      this.fetchPromotions();

  },
  watch: {
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = 'Market Square | Online Shopping | Groceries & Household Appliances | Find More, Pay Less';
        }
    },
  },
  methods: {
    goToCategory(name) {
      switch (name) {
        case "Black Friday": 
          return "/black-friday";
          break;
        case "First Image":
          return "/category/sda";
          break;
        case "Second Image":
          return "/category/medicare";
          break;
        case "Third Image":
          return "/category/spirits";
          break;
        case "landing page":
          return "/category/fresh produce";
          break;
        case "banner_5":
          return "/category/groceries";
          break;
        default:
          return null;
      }

      let res = Number(
        localStorage.getItem("black-friday-modal") != undefined
          ? localStorage.getItem("black-friday-modal")
          : 0
      );

      localStorage.setItem("black-friday-modal", res + 1);
    },
    goTo (name) {
      let result = this.goToCategory(name);
      
      if(result != null) {
        this.$router.push(result);
      } else if(name.toLowerCase().includes('xmas')) {
        
        this.$router.push('/category/hampers');
      }
    },
    fetchBanners () {
      let req = {
        what: "banners",
        showLoader: false,
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'banners') {
              this.banners = response.data.data.filter( (image) => image.category == null || image.category == 'null');
              this.$store.dispatch('banners', response.data.data)

            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    fetchCategories () {
      let req = {
        what: "getCategories",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getCategories') {
              this.categories = response.data.data
              this.$store.dispatch('categories', response.data.data)

            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    fetchPromotions () {
      let req = {
        what: "getPromotions",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getPromotions') {
              this.categories = response.data.data
              this.$store.dispatch('promotions', response.data.data.filter((val) => val != null))

            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    fetchProducts () {
      let req = {
        what: "products",
        showLoader: false,
        params: {
          storeid: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
        .then(res => {
          if (res.type == 'products') {
            console.log(res.data.data)
            let cart = this.$store.getters.cart;
            this.loader.hide()
            if (res.data.data.top.length > 0) {
              res.data.data.top.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                })
              })
              res.data.data.beverages.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }

                })
              })
              res.data.data.water.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }

                })
              })
              res.data.data.commodities.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }

                })
              })
              this.products = res.data.data;
            } else {
              this.$swal(
                "Empty store",
                `This store currently has no products. Please choose another store`,
                "error"
              );
            }
          }

        })
        .catch(error => {

          console.log(error)
        });
    },
    viewProduct (product) {
      if (typeof gtag !== 'undefined') {
          gtag('event', 'view_item', {
            currency: 'NGN',
            value: product.sellingprice,
            items: [
              {
                item_id: product.id,
                item_name: product.name,
                item_category: product.category,
                price: product.sellingprice,
              },
            ],
          });

          console.log('view_item event sent to Google Analytics');
      } else {
          console.warn('gtag is not defined. Cannot send view_item event.');
      }
      this.pro = product;
      this.viewproduct = true;
    },
    
  }
}

</script>

<style scoped>
.router:hover {
  cursor: pointer;
  width: 100% !important;
}
.header{
  background-color: #000066;
  /* border-radius: 25% 25% 0 0; */
  border-radius: 8px 8px 0 0;
}

@media screen and (max-width: 500px) {

.slider-item img{
width:100%;
height:100%;
}
}
.content-desktop {display: block;}
#content-mobile {display: none;}

@media screen and (max-width: 500px) {

.content-desktop {display: none;}
#content-mobile {display: block;}

}



.six {
  height:500px !important;
}

#catbox {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 10px;
  border-radius: 10px;
  padding: 1px;
  height: 60px;
  background-color: white;
  margin-top: 12px;
  color: rgb(161, 143, 143);

  width: 60px;
}
#catbox img{
  display: block !important; 
  margin-left: auto !important; 
  margin-right: auto !important; 
  margin-top: 8px !important;
  width: 40px !important;
  
}
/*.home, #app{
background: black !important;
}*/
</style>
